import React from "react";
import PropTypes from "prop-types";
import { Menu } from "./menu";
import "@fontsource/roboto-slab";
import "./layout.css";

const Layout = ({ children }) => {
  return (
    <main>
      <div className={"wrapper-main"}>{children}</div>
      <Menu />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Layout };
