import * as React from "react";
import { SEO } from "../components/seo";
import { Layout } from "../components/layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <div style={{ padding: "2rem" }}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <SEO title={"404: Not found"} />;
